var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col"
  }, [_vm.heathiansMiddle && _vm.heathiansMiddle.url ? _c('div', [_c('b-img', {
    staticClass: "w-100 h-100",
    attrs: {
      "fluid": "",
      "src": _vm.heathiansMiddle.url.fileUrl
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }