<template>
  <div class="col">
    <div v-if="heathiansMiddle && heathiansMiddle.url">
      <b-img
        fluid
        class="w-100 h-100"
        :src="heathiansMiddle.url.fileUrl"
      ></b-img>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "healthians-middle-banner",
  computed: {
    ...mapState({
      heathiansMiddle: ({ advertisement }) => advertisement.heathiansMiddle,
    }),
  },
  methods: {
    ...mapActions({
      fetchHealthiansMiddleBannerData: "advertisement/healthiansWebMiddle",
    }),
  },
  mounted() {
    this.fetchHealthiansMiddleBannerData();
  },
};
</script>
